<template>
  <div class="form-group">
    <label :for="'email'+seq.toString()" class="">Email {{seq}}</label>
    <input :name="'email'+seq.toString()" type="email" class="form-control"  v-model="email"  @change="validateEmail()" />
    <ul class="error-feedback" v-if="emailErrors.length > 0" v-html="emailErrors"></ul>
  </div>

</template>

<script>

export default {
  name: 'InviteOneFamily',
  props: {
    seq:Number
  },
  data(){
    return{
      email:'',
      emailErrors:'',
      formData:{}
    }
  },
  methods:{
    validateEmail() {
      //console.log('email is '+this.email);
      let errors = (/\S+@\S+\.\S+/.test(this.email)) ? '' : '<li>email is not valid</li>';
      errors += this.$root.validateLength(this.email,0,100);
      this.emailErrors = errors;
      if(this.emailErrors.length > 0){
        this.hasValidationError = true;
      }
      if(this.hasValidationError){
        this.$emit('emailUpdated',{seq:this.seq, value:''})
      }else{
        this.$emit('emailUpdated',{seq:this.seq, value:this.email})
      }
    },
  }

}
</script>

<style scoped>
.error-feedback{
  font-size:10px;
  color:red;

}
</style>
