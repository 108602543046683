<template>
  <div class="container " style="margin-top:70px;">
    <div style="display:flex;">
      <back-button />
      <h5 style="margin:auto;">Invite Other Families</h5>
    </div>
    <hr>

    <div class="alert alert-warning clickable" @click="gotoManageFamilyMember">This is to share your love of Goblin Bank with the other families. To add members to your family, please click here.</div>

    <div id="inviteContainer" >
      <form id="inviteForm" @submit.prevent="handleFormSubmission" autocomplete="false" v-if="!successful">


        <div class="form-group">
          <label for="yourName">Your name known to the recipients</label>
          <input name="yourName" type="text" class="form-control" v-model="yourName"  @change="validateYourName()"/>
          <ul class="error-feedback" v-if="yourNameErrors.length > 0" v-html="yourNameErrors"></ul>
        </div>


        <InviteOneFamily v-for="seq in num" :key="seq" :seq="seq" @emailUpdated="emailUpdated"/>

        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="loading">
                    <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm"
                    ></span>
            Send Invitations
          </button>
        </div>


      </form>
    </div>
    <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'" v-html="message"
    >
    </div>
  </div>

</template>

<script>

import InviteOneFamily from "@/components/InviteOneFamily";

export default {
  name: 'InviteOthers',
  components: {
    InviteOneFamily
  },
  data(){


    return {
      successful: false,
      loading: false,
      message: "",
      yourName:'',
      yourNameErrors:'',
      hasValidationError: false,
      formData:{},
      emailFields:[
          'email1',
          'email2',
          'email3',
          'email4',
          'email5',
          'email6',
          'email7',
          'email8',
          'email9',
          'email10'
      ],
      emails:{},
      num:10,






    };
  },
  computed:{

  },
  methods:{


    handleFormSubmission(){
      this.$root.playClickSound();

      this.hasValidationError = false;
      this.message = "";
      this.successful = false;


      this.validateYourName();

      var validEmails = 0;
      for (const emailId in this.emails) {
        if(this.emails[emailId].length > 0) {
          validEmails++;
        }
      }

      if(validEmails === 0){
        this.successful = false;
        this.message = "At least one email is required.";
        this.hasValidationError = true;
      }

      if(this.hasValidationError) return;

      this.loading = true;

      this.axios
          .post(this.$hostApi,{task:'inviteOthers', yourName:this.yourName, emails:this.emails},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.message = 'Emails have been sent successfully!';
            this.successful = true;

            this.loading = false;
            //console.log(response);
          })
          .catch(error => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.successful = false;
            this.loading = false;
          });

    },

    validateYourName(){
      this.yourNameErrors = this.$root.validateLength(this.yourName,3,20);
      if(this.yourNameErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    emailUpdated:function(data){
      this.emails[data.seq] = data.value;
      console.log(this.emails);
    },

    gotoManageFamilyMember(){
      this.$root.gotoRoute({ path: '/settings/family'});
    }
  },
  created() {


  },
  mounted() {
    document.title = "Invite Others";
  },

}
</script>

<style scoped>
.form-group{
  margin:15px 0;
}

.error-feedback{
  font-size:10px;
  color:red;

}

#inviteContainer{
  padding:20px;

}

#inviteForm{

}



</style>